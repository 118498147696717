import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react'
import './index.css';
import PopupDeleteDataset from './popupDeleteDataset';
import GlobalState, {changepopupdataset, changepopupdeleteDataset, adddataset, cleardataset, changedatasetflag, minusnumpagesdatasets, plusnumpagesdatasets, changedatasettottal} from "../../stores/GlobalState";
import Pool from "../autorize/UserPool";
import Domen from "../../stores/Domen";
import lang from "../../lang"
const Sidebar = observer(() => {
    
    const [deleteId, setdeleteId] = useState("");
    const [deleteIdnum, setdeleteIdnum] = useState("");
    async function getDataAttach()
    {
       
        var token = localStorage.getItem("token");
        const response = await fetch(Domen.url + `dataset?page=`+GlobalState.numpagesdatasets+`&limit=10`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            }
        })
        if(response.status=="200")
        {
            const data = await response.json();
            cleardataset();
            if(data&&data.items.length>0)
            {
                data.items?.map((n, id)=>
                    {
                       
                        var dataset = {
                            id: n.id,
                            name: n.name,
                            source: n.source,
                            last_changes: n.upload_time,
                            rows: n.rowsCount,
                            columns: n.columnsCount,
                            object_ID: n.objectId,
                            used_in_models: n.usedInModels,
                            file_key: n.id,
                            file_name: n.file_name,
                            key_column: n.objectId,
                            status: n.status,
                            upload_time: n.upload_time,
                            uploadedAt: n.uploadedAt,
                            user_id: n.user_id,
                            columnNames: n.columnNames
                        }
                        
                        adddataset(dataset);
                        return "";
                    }
                    
                )
            }
            changedatasettottal(data.total)

        }
        else
        {
            const user = Pool.getCurrentUser();
            user.getSession((err, session) => {
                if (err) {
                    console.error("Error fetching user session:", err);                       
                } 
                else 
                {
                    if (!session) {
                        console.log("No session found");
                        return;
                    }
                    else
                    {
                        const refreshToken  = session.getRefreshToken();
                        user.refreshSession(refreshToken, (err, result) => {
                            const { accessToken } = result;
                            localStorage.setItem("token", accessToken.jwtToken);
                            getDataAttach();
                        });
                    }
                    
                }
            })
        }
    }
    const minusnumpagesmodels0 = () =>{
        minusnumpagesdatasets();
        getDataAttach();
    }
    const plusnumpagesmodels0= () =>{
        plusnumpagesdatasets();
        getDataAttach();
    }
    const [langmenu, setLangmenu] = useState(localStorage.getItem("lang_site")||"en")
    const [langv, setLangv]= useState(langmenu=="de"?lang.de:lang.en)
    if(GlobalState.datasetflag==0){ 
        getDataAttach();
        changedatasetflag(1);
    }
   
    const deleteDataset = (n, id) =>{

       
        setdeleteId(n)
        setdeleteIdnum(id)
        changepopupdeleteDataset(n, id);
        
       
    }
    return (
        <>
        <div className="segment datasets" >
            <div className='body-segment' style={{marginTop:"40px"}}>
                <div className='add-segment-but dataset' onClick={changepopupdataset}>
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.4226 15C32.7799 15.6188 32.7799 16.3812 32.4226 17L25.5773 28.8564C25.2201 29.4752 24.5598 29.8564 23.8453 29.8564L10.1547 29.8564C9.44017 29.8564 8.77991 29.4752 8.42265 28.8564L1.57735 17C1.22008 16.3812 1.22008 15.6188 1.57735 15L8.42265 3.14359C8.77991 2.52479 9.44017 2.14359 10.1547 2.14359L23.8453 2.14359C24.5598 2.14359 25.2201 2.52479 25.5774 3.14359L32.4226 15Z" fill="#F5C538" stroke="#F5C538"/>
                        <path d="M17 9V23" stroke="#020203" stroke-linecap="round"/>
                        <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M17 9V23" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M10 16H24" stroke="#020203" stroke-linecap="round"/>
                        <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                        <path d="M10 16H24" stroke="black" stroke-opacity="0.2" stroke-linecap="round"/>
                    </svg>
                    {langv?.dataset?.main?.text1}
                </div>
                <div className='line_head'></div>
                <div className='betta'>
                    <div className='betta_inside'>
                    {langv?.beta?.head_text}
                    </div>
                </div>
            </div>
            <div className='header-segment dataset'>
                <div className='datasethead'>
                    <div className='topfield field1'>{langv?.dataset?.main?.text2}</div>
                    <div className='topfield field2'>{langv?.dataset?.main?.text3}</div>
                    <div className='topfield field3'>{langv?.dataset?.main?.text4}</div>
                    <div className='topfield field4'>{langv?.dataset?.main?.text5}</div>
                    <div className='topfield field5'>{langv?.dataset?.main?.text6}</div>
                    <div className='topfield field6'>{langv?.dataset?.main?.text7}</div>
                    <div className='topfield field7'>{langv?.dataset?.main?.text8}</div>
                </div>
                {
                    
                    GlobalState.dataset?.map((n, id)=>
                        
                        <div className='datasetbody'>
                            
                            {/* <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 1.57735C8.6188 1.22008 9.3812 1.22008 10 1.57735L15.7942 4.92265C16.413 5.27992 16.7942 5.94017 16.7942 6.6547L16.7942 13.3453C16.7942 14.0598 16.413 14.7201 15.7942 15.0774L10 18.4226C9.3812 18.7799 8.6188 18.7799 8 18.4226L2.20577 15.0774C1.58697 14.7201 1.20577 14.0598 1.20577 13.3453L1.20577 6.6547C1.20577 5.94017 1.58697 5.27992 2.20577 4.92265L8 1.57735Z" stroke="#1C1E20" stroke-width="1.5"/>
                            </svg>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.6">
                                <path d="M18.1761 5.82349L18.3541 6.0029L18.5316 5.82291L19.2509 5.0932C19.2512 5.09299 19.2514 5.09278 19.2516 5.09257C19.8033 4.54261 20.75 4.93632 20.75 5.71488V9.75267C20.75 10.2329 20.3555 10.6274 19.8738 10.6274H15.831C15.055 10.6274 14.665 9.68463 15.2193 9.131L16.9198 7.43265L17.0961 7.25657L16.9206 7.07969C15.661 5.81 13.9317 5.00374 11.9908 5.00374H11.9906C10.94 5.0047 9.90298 5.24154 8.95635 5.69677C8.00972 6.152 7.17764 6.81396 6.52167 7.63373C5.86571 8.45349 5.40265 9.41007 5.16676 10.4327C4.93099 11.4549 4.9282 12.5168 5.15859 13.5402C5.70862 16.1318 7.79801 18.2418 10.3927 18.8144C11.9585 19.1796 13.6025 18.9953 15.0483 18.2924C16.4939 17.5897 17.6533 16.4113 18.3315 14.9553C18.4661 14.6796 18.7668 14.5016 19.0968 14.5016C19.744 14.5016 20.1668 15.1737 19.8848 15.7634L19.8845 15.764C19.176 17.2558 18.0582 18.5162 16.6609 19.3988C15.2636 20.2815 13.6442 20.75 11.9908 20.75C6.81206 20.75 2.70644 16.2785 3.30884 10.9731L3.30888 10.9727C3.75746 6.96246 7.06495 3.70239 11.0811 3.298L11.0823 3.29789C12.3786 3.16142 13.6891 3.31753 14.9169 3.75468C16.1448 4.19183 17.2586 4.89884 18.1761 5.82349Z" fill="#1C1E20" stroke="white" stroke-width="0.5"/>
                                </g>
                            </svg> */}

                            <div className='topfield field1'>
                                <input
                                    id="dataset"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.name}
                                    //onChange={(event) => setDataset(event.target.value)}
                                />


                            </div>
                            <div className='topfield field2'>
                                <input
                                    id="source"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.source}
                                    //onChange={(event) => setSource(event.target.value)}
                                />
                            </div>
                            <div className='topfield field3'>
                                <input
                                    id="lastchanges"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.uploadedAt}
                                    //onChange={(event) => setLastchanges(event.target.value)}
                                />
                            </div>
                            <div className='topfield field4'>
                                <input
                                    id="rows"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.rows?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                    //onChange={(event) => setRows(event.target.value)}
                                />
                            </div>
                            <div className='topfield field5'>
                                <input
                                    id="columns"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.columns}
                                    //onChange={(event) => setColumns(event.target.value)}
                                />
                            </div>
                            <div className='topfield field6'>
                                <input
                                    id="objectid"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.object_ID}
                                    //onChange={(event) => setObjectid(event.target.value)}
                                />
                            </div>
                            <div className='topfield field7'>
                                <input
                                    id="usedinmodels"
                                    type="text"
                                    disabled="true"
                                    placeholder=""
                                    className="pop-input"
                                    value={n.used_in_models}
                                    //onChange={(event) => setUsedinmodels(event.target.value)}
                                />
                            </div>
                            <svg onClick={()=>deleteDataset(n.id, id)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.4551 5.27344L5.90838 18.18" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                                <path d="M5.72656 5.45312L18.6332 17.9998" stroke="#EA4334" stroke-width="2" stroke-linecap="round"/>
                            </svg>

                        </div>
                    )
                }
                
            </div>
        </div>
        <div className='pagination_line'>
            <div className='countpagepagination'>
                {langv?.dataset?.main?.text9}  {(GlobalState.numpagesdatasets*1-1)*10+1} -&nbsp;
                
                {
                    GlobalState.numpagesdatasets*10<GlobalState.datasetstottal?
                    GlobalState.numpagesdatasets*10
                    :
                    GlobalState.datasetstottal
                }
                 &nbsp;of {GlobalState.datasetstottal} {langv?.dataset?.main?.text10} 
            </div>
        
            <div className='pagination pagdatasets'>
                {
                    GlobalState.numpagesdatasets<2?
                    <div className='arrowP cursorenone' >

                        <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.1">
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            </g>
                        </svg>

                        


                    </div>
                    :
                    <div className='arrowP' onClick={()=>minusnumpagesmodels0()}>
                        <svg className='def_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            </g>
                        </svg>

                        <svg className='hov_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="url(#paint0_linear_3491_117361)"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="#020203"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            <path d="M24.6377 8L19.7692 13M19.7692 13L24.6377 18M19.7692 13H34" stroke="black" stroke-opacity="0.2"/>
                            <defs>
                            <linearGradient id="paint0_linear_3491_117361" x1="1" y1="13" x2="45" y2="13" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#F5C538"/>
                            <stop offset="1" stop-color="#F5C538" stop-opacity="0"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                }
                
                <div className='pagenum'>

                    {
                        GlobalState.datasetstottal<10?
                        <span>01</span>: 
                        GlobalState.numpagesdatasets<10?
                        <span>{"0"+GlobalState.numpagesdatasets}</span>
                        :GlobalState.numpagesdatasets
                    }
                    / 
                    {
                        GlobalState.datasetstottal<10?
                        "01":
                        Math.ceil(GlobalState.datasetstottal/10)<10?
                        "0"+Math.ceil(GlobalState.datasetstottal/10):
                        Math.ceil(GlobalState.datasetstottal/10)
                    }
                </div>
                
                    {
                        GlobalState.datasetstottal<10?
                        <div className='arrowP cursorenone'>
                            <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.1">
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                </g>
                            </svg>
                        </div>
                        :
                        GlobalState.numpagesdatasets==Math.ceil(GlobalState.datasetstottal/10)?
                        <div className='arrowP cursorenone'>
                            <svg className='cursorenone' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.1">
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                </g>
                            </svg>
                        </div>
                        :
                        <div className='arrowP' onClick={()=>plusnumpagesmodels0()}>
                            <svg className='def_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="#020203"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                </g>
                            </svg>
                            <svg className='hov_arrow' width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="2.5" width="53" height="21" rx="4.5" stroke="url(#paint0_linear_3491_117355)"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="#020203"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                <path d="M29.3623 8L34.2308 13M34.2308 13L29.3623 18M34.2308 13H20" stroke="black" stroke-opacity="0.2"/>
                                <defs>
                                <linearGradient id="paint0_linear_3491_117355" x1="53" y1="13" x2="10" y2="13" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#F5C538"/>
                                <stop offset="1" stop-color="#F5C538" stop-opacity="0"/>
                                </linearGradient>
                                </defs>
                            </svg>
                        </div>

                    }
                    
                
            </div>
        </div>
        {/* {GlobalState.ispopupdeleteDatasetShow ?<PopupDeleteDataset id={{deleteId}} deleteIdnum = {{deleteIdnum}}/>:<></>} */}
        </>
    );
})

export default Sidebar;
